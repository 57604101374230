import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import QuizIcon from '@mui/icons-material/Quiz';

import i18next from '../../translations/i18n';

const getSidebarConfig = () => {
  return [
    {
      subheader: i18next.t('sidebar.menuHeaderPages'),
      items: [
        {
          title: i18next.t('sidebar.menuItemUsers'),
          path: '/users',
          icon: <GroupIcon />,
        },
        {
          title: i18next.t('sidebar.menuItemOrganisations'),
          path: '/organisations',
          icon: <BusinessIcon />,
        },
        {
          title: i18next.t('sidebar.menuItemGroups'),
          path: '/allgroups',
          icon: <Diversity3Icon />,
        },
        {
          title: i18next.t('sidebar.menuItemCompobots'),
          path: '/compobots',
          icon: <SmartToyIcon />,
        },
        {
          title: i18next.t('sidebar.menuItemChallanges'),
          path: '/challenges',
          icon: <AssignmentIcon />,
        },
        {
          title: i18next.t('sidebar.menuItemQuiz'),
          path: '/quiz',
          icon: <QuizIcon />,
        },
        {
          title: i18next.t('sidebar.menuItemNotifications'),
          path: '/notifications',
          icon: <NotificationsIcon />,
        },
        {
          title: i18next.t('sidebar.menuItemLeads'),
          path: '/leads',
          icon: <InsightsIcon />,
        },
      ],
    },
    {
      subheader: i18next.t('sidebar.menuHeaderOther'),
      items: [
        {
          title: i18next.t('sidebar.menuItemLogout'),
          path: '/',
          exact: true,
          icon: <LogoutIcon />,
        },
      ],
    },
  ];
};

export default getSidebarConfig;
