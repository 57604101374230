import { CreateChallengeType } from 'types/challenges.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const createChallenge = async (challenge: CreateChallengeType) => {
  return await api.post('challenges', challenge);
};

export const modifyChallenge = async (
  id: string,
  challenge: CreateChallengeType,
) => {
  return await api.put(`challenges/${id}`, challenge);
};

export const getChallenges = async () => {
  return await api.get('challenges');
};

export const getChallengeById = async (id: string) => {
  return await api.get(`challenges/${id}`);
};

export const deleteChallenge = async (id: string) => {
  return await api.delete(`challenges/${id}`);
};
