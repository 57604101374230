import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PinIcon from '@mui/icons-material/Pin';

import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type UserMoreMenuProps = {
  onDelete: VoidFunction;
  onChangePin?: VoidFunction;
  onPreview?: VoidFunction;
  id?: string;
  groups?: boolean;
  hasPin?: boolean;
  isNotification?: boolean;
  isNotificationDelivered?: boolean;
};

export default function MoreMenu({
  onDelete,
  onChangePin,
  onPreview,
  id,
  groups,
  hasPin,
  isNotification,
  isNotificationDelivered,
}: UserMoreMenuProps) {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          component={RouterLink}
          to={`${id}`}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            {isNotification && isNotificationDelivered ? (
              <PreviewIcon />
            ) : (
              <EditIcon />
            )}
          </ListItemIcon>
          <ListItemText
            primary={
              isNotification && isNotificationDelivered
                ? t('moreMenu.view')
                : t('moreMenu.edit')
            }
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
        {onPreview && (
          <MenuItem onClick={onPreview} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <PreviewIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('moreMenu.view')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {groups && (
          <MenuItem
            component={RouterLink}
            to={`${id}/groups`}
            sx={{ color: 'text.secondary' }}
          >
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('moreMenu.manageGroups')}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        {hasPin && (
          <MenuItem sx={{ color: 'text.secondary' }} onClick={onChangePin}>
            <ListItemIcon>
              <PinIcon />
            </ListItemIcon>
            <ListItemText
              primary="PIN"
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        )}
        <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('moreMenu.delete')}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
