import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import PageHeader from 'components/PageHeader/PageHeader';
import CreateQuizForm from 'components/CreateQuizForm.tsx/CreateQuizForm';

export default function CreateQuizPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <Container>
      <PageHeader
        hasBackButton
        hasButton={false}
        title={
          id ? t('createQuizPage.titleModify') : t('createQuizPage.titleCreate')
        }
      />
      <CreateQuizForm />
    </Container>
  );
}
