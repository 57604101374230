import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import {
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getAllGroupsTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import { saveAs } from 'file-saver';
import { debounce } from 'lodash';
import * as QRCode from 'qrcode';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  deleteGroup,
  deleteGroupBulk,
  getGroups,
} from 'services/group.service';
import { sortByColumn } from 'services/list.service';
import { GroupType } from 'types/groups.type';

export default function AllGroupsPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [orgDetails] = useState<{
    orgName: string;
    orgId: string;
  }>({ orgName: '', orgId: '' });
  const [groupList, setGroupList] = useState<GroupType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const fetchGroups = async () => {
    try {
      const response = await getGroups();
      return response.data;
    } catch (error) {
      console.error('Error fetching groups:', error);
      throw new Error('Error fetching groups');
    }
  };

  const filterGroups = (groups: GroupType[], filterName: string) => {
    return groups.filter(
      (group) =>
        group.displayName.toLowerCase().includes(filterName.toLowerCase()) ||
        (t('groupsPage.private')
          .toLocaleLowerCase()
          .includes(filterName.toLocaleLowerCase()) &&
          !group.organisation) ||
        (group.organisation &&
          group.organisation.companyName
            .toLowerCase()
            .includes(filterName.toLowerCase())),
    );
  };

  const handleSearch = async (filterName: string) => {
    try {
      let groups: GroupType[] = await fetchGroups();
      if (filterName) {
        groups = filterGroups(groups, filterName);
      }
      setGroupList(groups);
    } catch (error) {
      console.error('Error handling search:', error);
      throw new Error('Error handling search');
    }
  };

  useEffect(() => {
    fetchGroups()
      .then((groups) => {
        const updatedGroups = groups.map((group: GroupType) => ({
          ...group,
          numberOfUsers: group.users?.length,
        }));
        setGroupList(updatedGroups);
      })
      .catch((error) => {
        console.error('Error fetching groups:', error);
      });
  }, []);

  const searchFilter = useCallback(
    debounce((filterName) => {
      handleSearch(filterName);
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      setSelected(groupList.map((n: GroupType) => n.id));
      return;
    }

    setSelected([]);
  };

  const handleClick = (name: string) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteGroup = (groupId: string) => {
    deleteGroup(groupId)
      .then(() => {
        setGroupList(groupList.filter(({ id }) => id !== groupId));
        handleSelectAllClick(false);
        setOpenModal(false);
        handleOpen(t('groupsPage.groupDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteGroupBulk = (ids: string[]) => {
    deleteGroupBulk(ids)
      .then(() => {
        setGroupList(groupList.filter(({ id }) => !ids.includes(id)));
        setOpenModal(false);
        handleSelectAllClick(false);
        handleOpen(t('groupsPage.groupsDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteGroupAction = () => {
    if (selected.length > 1) {
      handleDeleteGroupBulk(selected);
    } else {
      handleDeleteGroup(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);

    if (id) {
      setSelected([id]);
    }
  };

  const handleGenerateQRCode = async (groupId: string) => {
    const url = `compocityplus://groups?groupId=${groupId}`;

    QRCode.toString(url, function (err, svgString) {
      if (err) {
        console.error('Error generating QR code:', err);
      } else {
        const svgXml = new XMLSerializer().serializeToString(
          new DOMParser().parseFromString(svgString, 'image/svg+xml'),
        );

        const blob = new Blob([svgXml], { type: 'image/svg+xml' });

        saveAs(blob, 'qr_code.svg');
      }
    });
  };

  const sortedGroupsList = sortByColumn(
    groupList,
    orderBy as keyof GroupType,
    order,
  );

  const groupsAllTableHead = useMemo(getAllGroupsTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={`${orgDetails.orgName} ${t('groupsPage.title')}`}
        buttonTitle={t('groupsPage.buttonTitle').toString()}
        hasButton
      />
      <Card>
        <TableToolBar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={groupsAllTableHead}
              rowCount={groupList.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />

            <TableBody>
              {sortedGroupsList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    displayName,
                    organisation,
                    numberOfUsers,
                    score,
                    users,
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => handleClick(id)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ fontWeight: 600 }}
                          >
                            {displayName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {numberOfUsers || users?.length}
                      </TableCell>
                      <TableCell align="left">
                        {organisation
                          ? organisation.companyName
                          : t('groupsPage.private')}
                      </TableCell>
                      <TableCell align="left">{score}</TableCell>
                      <TableCell align="left">
                        <LocalPrintshopOutlinedIcon
                          onClick={() => handleGenerateQRCode(id)}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          id={id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {!groupList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={groupList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteGroupAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
