import DashboardLayout from 'layouts/DashboardLayout';
import AssignCompobotPage from 'pages/AssignCompobotPage/AssignCompobotPage';
import ChallengesPage from 'pages/ChallengesPage/ChallengesPage';
import CompobotsPage from 'pages/CompoBotsPage/CompoBotsPage';
import CreateChallengePage from 'pages/CreateChallengePage/CreateChallengePage';
import CreateNotificationPage from 'pages/CreateNotificationPage/CreateNotificationPage';
import CreateOrganisationPage from 'pages/CreateOrganizationPage/CreateOrganizationPage';
import CreateGroupPage from 'pages/CreateGroupPage/CreateGroupPage';
import EditUserPage from 'pages/EditUserPage/EditUserPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import LeadsPage from 'pages/LeadsPage/LeadsPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import NotificationsPage from 'pages/NotificationsPage/NotificationsPage';
import OrganisationsPage from 'pages/OrganizationsPage/OrganizationsPage';
import GroupsPage from 'pages/OrganisationGroupsPage/OrganisationGroupsPage';
import UsersPage from 'pages/UsersPage/UsersPage';
import { createBrowserRouter } from 'react-router-dom';
import AllGroupsPage from 'pages/GroupsPage/GroupsPage';
import ViewChallengePage from 'components/ViewChallengeForm/ViewChallengeForm';
import QuizesPage from 'pages/QuizesPage/QuizesPage';
import CreateQuizPage from 'pages/CreateQuizPage/CreateQuizPage';
import ViewQuizPage from 'components/ViewQuizForm/ViewQuizForm';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'users', element: <UsersPage /> },
      { path: 'users/:id', element: <EditUserPage /> },
      {
        path: 'organisations',
        element: <OrganisationsPage />,
      },
      {
        path: 'organisations/new',
        element: <CreateOrganisationPage />,
      },
      {
        path: 'organisations/:id',
        element: <CreateOrganisationPage />,
      },
      {
        path: 'organisations/:id/groups',
        element: <GroupsPage />,
      },
      {
        path: 'organisations/:id/groups/new',
        element: <CreateGroupPage />,
      },
      {
        path: 'organisations/:id/groups/:groupId',
        element: <CreateGroupPage />,
      },
      {
        path: 'allgroups',
        element: <AllGroupsPage />,
      },
      {
        path: 'allgroups/new',
        element: <CreateGroupPage />,
      },
      {
        path: 'allgroups/:groupId',
        element: <CreateGroupPage />,
      },
      {
        path: 'leads',
        element: <LeadsPage />,
      },

      { path: 'compobots', element: <CompobotsPage /> },
      { path: 'compobots/new', element: <AssignCompobotPage /> },
      { path: 'compobots/:id', element: <AssignCompobotPage /> },
      { path: 'challenges', element: <ChallengesPage /> },
      { path: 'challenges/:id', element: <CreateChallengePage /> },
      { path: 'challenges/new', element: <CreateChallengePage /> },
      { path: 'notifications', element: <NotificationsPage /> },
      { path: 'notifications/:id', element: <CreateNotificationPage /> },
      { path: 'notifications/new', element: <CreateNotificationPage /> },
      { path: 'view/challenge/:id', element: <ViewChallengePage /> },

      {
        path: 'quiz',
        element: <QuizesPage />,
      },
      {
        path: 'quiz/new',
        element: <CreateQuizPage />,
      },
      {
        path: 'quiz/:id',
        element: <CreateQuizPage />,
      },
      {
        path: 'view/quiz/:id',
        element: <ViewQuizPage />,
      },
    ],
  },
]);
