import {
  Card,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import MoreMenu from 'components/table/MoreMenu';
import SearchNotFound from 'components/table/SearchNoFound';
import TableHeader from 'components/table/TableHeader';
import TableToolBar from 'components/table/TableToolbar';
import { getUsersTableHead } from 'constans/table-headers';
import SnackbarContext from 'contexts/snackbarContext';
import {
  handleClick,
  handleSelectAllClick,
  sortByColumn,
} from 'services/list.service';
import {
  deleteUser,
  deleteUserBulk,
  getUsers,
  searchUsers,
} from 'services/users.services';
import { UserType } from 'types/users.type';

export default function UsersPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [userList, setUserList] = useState<UserType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getUsersHandler();
  }, []);

  const getUsersHandler = () => {
    getUsers().then((response) => {
      setUserList(response.data);
    });
  };

  const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchUsers(filterName).then((response) => {
          setUserList(response.data);
        });
      } else {
        getUsersHandler();
      }
    }, 300),
    [],
  );

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteUser = (userId: string) => {
    deleteUser(userId)
      .then(() => {
        setUserList([...userList].filter(({ id }) => id !== userId));
        handleSelectAllClick(false, userList);
        setOpenModal(false);
        handleOpen(t('usersPage.userDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const handleDeleteUserBulk = (userIds: string[]) => {
    deleteUserBulk(userIds)
      .then(() => {
        setUserList(userList.filter(({ id }) => !userIds.includes(id)));
        handleSelectAllClick(false, userList);
        setOpenModal(false);
        handleOpen(t('usersPage.usersDeletedSuccessMessage'), 'success');
      })
      .catch(({ data }) => {
        handleOpen(data.message);
      });
  };

  const deleteUsersAction = () => {
    if (selected.length > 1) {
      handleDeleteUserBulk(selected);
    } else {
      handleDeleteUser(selected[0]);
    }
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);
    if (id) {
      setSelected([id]);
    }
  };

  const sortedUsersList = sortByColumn(
    userList,
    orderBy as keyof UserType,
    order,
  );

  const usersTableHead = useMemo(getUsersTableHead, [language]);

  return (
    <Container>
      <PageHeader title={t('usersPage.title')} hasButton={false} />
      <Card>
        <TableToolBar
          numSelected={selected.length}
          onFilterName={searchFilter}
          deleteHandler={() => openModalHandler()}
        />

        <TableContainer>
          <Table>
            {
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={usersTableHead}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={(checked) =>
                  setSelected(handleSelectAllClick(checked, userList))
                }
              />
            }
            <TableBody>
              {sortedUsersList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const {
                    id,
                    firstname,
                    lastname,
                    email,
                    displayName,
                    organisation,
                    score,
                  } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => setSelected(handleClick(id, selected))}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ fontWeight: 600 }}
                          >
                            {`${firstname} ${lastname}`}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{displayName ?? '-'}</TableCell>

                      <TableCell align="left">{email}</TableCell>
                      <TableCell align="left">
                        {organisation
                          .map((org) => org.companyName)
                          .join(', ') || '-'}
                      </TableCell>
                      <TableCell align="left">{score}</TableCell>

                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          id={id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
            {!userList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={userList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={deleteUsersAction}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
