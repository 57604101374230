/* eslint-disable indent */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { QuizType } from 'types/quizes.type';
import { getQuizes, deleteQuiz } from 'services/quiz.service';
import { handleSelectAllClick, sortByColumn } from 'services/list.service';
import PageHeader from 'components/PageHeader/PageHeader';
import TableHeader from 'components/table/TableHeader';
import SnackbarContext from 'contexts/snackbarContext';
import { getQuizTableHead } from 'constans/table-headers';
import MoreMenu from 'components/table/MoreMenu';
import Popup from 'components/Popup/Popup';

export default function QuizesPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [quizList, setQuizList] = useState<QuizType[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getQuizesHandler();
  }, []);

  const getQuizesHandler = () => {
    getQuizes().then((response) => {
      setQuizList(response.data);
    });
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getQuizList = () => {
    getQuizes().then(({ data }) => setQuizList(data));
  };

  const deleteQuizHandler = (id: string) => {
    deleteQuiz(id).then(() => {
      setOpenModal(false);
      handleOpen(t('challengesPage.challengeDeletedSuccessMessage'), 'success');
      getQuizList();
    });
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);

    if (id) {
      setSelected([id]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleView = (id: string, navigate: any) => {
    navigate(`/view/quiz/${id}`);
  };

  const sortedQuiz = sortByColumn(quizList, orderBy as keyof QuizType, order);

  const quizTableHead = useMemo(getQuizTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={t('quizesPage.title')}
        buttonTitle={t('quizesPage.buttonTitle').toString()}
        hasButton
      />
      <Card
        sx={{
          paddingTop: 1,
        }}
      >
        <TableContainer>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={quizTableHead}
              rowCount={quizList.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={(checked) =>
                setSelected(handleSelectAllClick(checked, quizList))
              }
              disableSelect
            />
            {/* If there will be a search put the TableHeader here */}
            <TableBody>
              {sortedQuiz
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((quiz) => (
                  <TableRow
                    hover
                    key={quiz.id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={selected.indexOf(quiz.id) !== -1}
                    aria-checked={selected.indexOf(quiz.id) !== -1}
                  >
                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography
                          variant="subtitle2"
                          noWrap
                          sx={{ fontWeight: 600 }}
                        >
                          {quiz.title_EN}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">
                      {quiz.challenges?.length > 0
                        ? quiz.challenges
                            .map((challenge) => challenge.name_EN)
                            .join(', ')
                        : t('quizesPage.notAssignedChallenge')}
                    </TableCell>
                    <TableCell align="left">{quiz.scoreForCorrect}</TableCell>
                    <TableCell align="right">
                      <MoreMenu
                        onDelete={() => openModalHandler(quiz.id)}
                        onPreview={() => handleView(quiz.id, navigate)}
                        id={quiz.id}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {!quizList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    {t('quizesPage.noQuizes')}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={quizList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={() => deleteQuizHandler(selected[0])}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
