import {
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';

import PageHeader from 'components/PageHeader/PageHeader';
import Popup from 'components/Popup/Popup';
import SnackbarContext from 'contexts/snackbarContext';
import { deleteChallenge, getChallenges } from 'services/challenge.service';
import { ChallengeType } from 'types/challenges.type';
import TableHeader from 'components/table/TableHeader';
import { getChallengeTableHead } from 'constans/table-headers';
import { handleSelectAllClick, sortByColumn } from 'services/list.service';
import MoreMenu from 'components/table/MoreMenu';
import { useNavigate } from 'react-router-dom';
import { getOrganizations } from 'services/organisation.service';
import { OrganisationType } from 'types/organisations.type';

export default function ChallengesPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleOpen } = useContext(SnackbarContext);
  const [challengeList, setChallengeList] = useState<ChallengeType[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [organisations, setOrganisations] = useState<OrganisationType[]>([]);

  useEffect(() => {
    getChallengesHandler();
  }, []);

  useEffect(() => {
    if (challengeList) {
      console.log(challengeList);
      getOrganizations().then(({ data }: { data: OrganisationType[] }) => {
        setOrganisations(data);
      });
    }
  }, [challengeList]);

  const getOrganisationNames = (organisationIds: string[]) => {
    if (organisationIds) {
      const challengeOrgs = organisations.filter((org) =>
        organisationIds.includes(org.id),
      );
      return challengeOrgs.map((org) => org.companyName).join(', ');
    } else {
      return [];
    }
  };

  const getChallengesHandler = () => {
    getChallenges().then((response) => {
      setChallengeList(response.data as ChallengeType[]);
    });
  };

  // We don't have EP for that, maybe add later
  /* const searchFilter = useCallback(
    debounce((filterName) => {
      if (filterName) {
        searchUsers(filterName).then((response) => {
          setChallengeList(response.data);
        });
      } else {
        getChallengesHandler();
      }
    }, 300),
    [],
  ); */

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getChallengeList = () => {
    getChallenges().then(({ data }) => setChallengeList(data));
  };

  const deleteChallengeHandler = (id: string) => {
    deleteChallenge(id).then(() => {
      setOpenModal(false);
      handleOpen(t('challengesPage.challengeDeletedSuccessMessage'), 'success');
      getChallengeList();
    });
  };

  const openModalHandler = (id?: string) => {
    setOpenModal(true);

    if (id) {
      setSelected([id]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleView = (id: string, navigate: any) => {
    navigate(`/view/challenge/${id}`);
  };

  const sortedChallenge = sortByColumn(
    challengeList,
    orderBy as keyof ChallengeType,
    order,
  );

  const challengeTableHead = useMemo(getChallengeTableHead, [language]);

  return (
    <Container>
      <PageHeader
        title={t('challengesPage.title')}
        buttonTitle={t('challengesPage.buttonTitle').toString()}
        hasButton
      />
      <Card
        sx={{
          paddingTop: 1,
        }}
      >
        <TableContainer>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={challengeTableHead}
              rowCount={challengeList.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={(checked) =>
                setSelected(handleSelectAllClick(checked, challengeList))
              }
              disableSelect
            />
            {/* If there will be a search put the TableHeader here */}
            <TableBody>
              {sortedChallenge
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  ({
                    id,
                    name_EN,
                    startDate,
                    endDate,
                    currentScore,
                    targetScore,
                    organisationIds,
                  }) => (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selected.indexOf(id) !== -1}
                      aria-checked={selected.indexOf(id) !== -1}
                    >
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="subtitle2"
                            noWrap
                            sx={{ fontWeight: 600 }}
                          >
                            {name_EN}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        {organisations
                          ? getOrganisationNames(organisationIds)
                          : getOrganisationNames([])}
                      </TableCell>
                      <TableCell align="left">
                        {format(parseISO(startDate), 'yyyy-MM-dd')}
                      </TableCell>
                      <TableCell align="left">
                        {format(parseISO(endDate), 'yyyy-MM-dd')}
                      </TableCell>
                      <TableCell align="left">{currentScore}</TableCell>
                      <TableCell align="left">{targetScore}</TableCell>
                      <TableCell align="right">
                        <MoreMenu
                          onDelete={() => openModalHandler(id)}
                          onPreview={() => handleView(id, navigate)}
                          id={id}
                        />
                      </TableCell>
                    </TableRow>
                  ),
                )}
            </TableBody>
            {!challengeList.length && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    {t('challengesPage.noChallenges')}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={challengeList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('pagination.rowsPerPage')}
        />
      </Card>
      <Popup
        type="delete"
        open={openModal}
        onConfirm={() => deleteChallengeHandler(selected[0])}
        handleClose={() => setOpenModal(false)}
      />
    </Container>
  );
}
