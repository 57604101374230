import { CreateQuizType } from 'types/quizes.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const createQuiz = async (quiz: CreateQuizType) => {
  return await api.post('quiz', quiz);
};

export const modifyQuiz = async (id: string, quiz: CreateQuizType) => {
  return await api.put(`quiz/${id}`, quiz);
};

export const getQuizes = async () => {
  return await api.get('quiz');
};

export const getQuizById = async (id: string) => {
  return await api.get(`quiz/${id}`);
};

export const deleteQuiz = async (id: string) => {
  return await api.delete(`quiz/${id}`);
};

export const assignQuiz = async (
  quizId: string,
  challengeIds: { challengeIds: string[] },
) => {
  return await api.post(`quiz/${quizId}/assign/challenges`, challengeIds);
};

export const removeQuiz = async (quizId: string, challengeId: string) => {
  return await api.delete(`quiz/${quizId}/remove/${challengeId}`);
};

export const removeAllChallenges = async (quizId: string) => {
  return await api.delete(`quiz/remove/all-challenges/${quizId}`);
};
