import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorText from 'components/ErrorText/ErrorText';
import SnackbarContext from 'contexts/snackbarContext';
import { createGroup, getGroupById, modifyGroup } from 'services/group.service';
import { CreateGroupType } from 'types/groups.type';
import { OrganisationType } from 'types/organisations.type';

const CustomForm = styled('form')`
  width: 100%;
`;

interface CreateGroupFormProps {
  orgId?: string;
  organisationList: OrganisationType[];
}

export default function CreateGroupForm({
  orgId,
  organisationList,
}: CreateGroupFormProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<CreateGroupType>();

  const { id, groupId } = useParams();
  const { handleOpen } = useContext(SnackbarContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (groupId) {
      getGroupById(groupId)
        .then(({ data }) => {
          setValue('displayName', data.displayName);
          setValue('organisationId', data?.organisation?.id || 'Private');
        })
        .catch(({ data }) => {
          handleOpen(data?.message);
        });
    }
  }, []);

  const onSubmit = (displayName: CreateGroupType) => {
    const groupData = {
      ...displayName,
    };

    if (displayName.organisationId === 'Private') {
      groupData.organisationId = null;
    }

    if (groupId) {
      modifyGroup(groupId, groupData)
        .then(() => {
          navigate(-1);
          handleOpen(
            t('createGroupForm.groupModifiedSuccessMessage'),
            'success',
          );
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else if (id) {
      createGroup(groupData)
        .then(() => {
          navigate(-1);
          reset();
          handleOpen(
            t('createGroupForm.groupCreatedSuccessMessage'),
            'success',
          );
        })
        .catch(({ data }) => {
          handleOpen(data?.message);
        });
    } else if (!groupId && !id) {
      createGroup(groupData)
        .then(() => {
          navigate(-1);
          reset();
          handleOpen(
            t('createGroupForm.groupCreatedSuccessMessage'),
            'success',
          );
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {
      return;
    }
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '600px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="displayName"
              defaultValue=""
              rules={{
                required: true,
                maxLength: 30,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.displayName ? true : false}
                  fullWidth
                  autoComplete="text"
                  type="text"
                  inputProps={{ maxLength: 30 }}
                  label={t('formFields.displayName')}
                />
              )}
            />

            {errors.displayName && (
              <ErrorText>{t('formErrors.displayNameRequired')}</ErrorText>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">
                {t('formFields.organisation')}
              </InputLabel>
              <Controller
                name="organisationId"
                control={control}
                defaultValue={orgId}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    label={t('formFields.organisation')}
                    labelId="label"
                    error={errors.organisationId ? true : false}
                    disabled={!!orgId || !!groupId}
                  >
                    <MenuItem value="Private">
                      {t('groupsPage.private')}
                    </MenuItem>
                    {organisationList.map((organisation: OrganisationType) => {
                      return (
                        <MenuItem
                          key={organisation?.id}
                          value={organisation?.id}
                        >
                          {organisation?.companyName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">
          {t('formFields.submitButtonLabel')}
        </Button>
      </CustomForm>
    </Card>
  );
}
