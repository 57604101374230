import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateChallengeForm from 'components/CreateChallengeForm/CreateChallengeForm';
import PageHeader from 'components/PageHeader/PageHeader';

export default function CreateChallengePage() {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <Container>
      <PageHeader
        hasBackButton
        hasButton={false}
        title={
          id
            ? t('createChallengePage.titleModify')
            : t('createChallengePage.titleCreate')
        }
      />
      <CreateChallengeForm />
    </Container>
  );
}
