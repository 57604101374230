import { Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateGroupForm from 'components/CreateGroupForm/CreateGroupForm';
import PageHeader from 'components/PageHeader/PageHeader';
import { getOrganizations } from 'services/organisation.service';

interface RouteParams {
  id: string;
  groupId: string;
  [key: string]: string;
}

export default function CreateGroupPage() {
  const { id, groupId } = useParams<RouteParams>();
  const orgId = id || '';
  const { t } = useTranslation();
  const [organisationList, setOrganisationList] = useState([]);

  useEffect(() => {
    getOrganizations().then((response) => {
      setOrganisationList(response.data);
    });
  }, []);

  return (
    <Container>
      <PageHeader
        title={
          groupId
            ? t('createGroupPage.titleModify')
            : t('createGroupPage.titleCreate')
        }
        hasButton={false}
        hasBackButton={true}
      />
      <CreateGroupForm orgId={orgId} organisationList={organisationList} />
    </Container>
  );
}
