export default function Tooltip() {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '16px',
        },
      },
    },
  };
}
