type SortDirection = 'asc' | 'desc';

export const handleClick = (name: string, selected: string[]) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected: string[] = [];

  if (selectedIndex === -1) {
    newSelected = [...selected, name];
  } else if (selectedIndex === 0) {
    newSelected = selected.slice(1);
  } else if (selectedIndex === selected.length - 1) {
    newSelected = selected.slice(0, -1);
  } else if (selectedIndex > 0) {
    newSelected = [
      ...selected.slice(0, selectedIndex),
      ...selected.slice(selectedIndex + 1),
    ];
  }

  return newSelected;
};

export function sortByColumn<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<string, any>,
>(arr: T[], column: keyof T, order: SortDirection = 'asc'): T[] {
  return arr.sort((a, b) => {
    const isAsc = order === 'asc';
    let valueA: string | number | string[] = a[column];
    let valueB: string | number | string[] = b[column];

    if (column === 'name') {
      const nameA = `${a['firstname']} ${a['lastname']}`;
      const nameB = `${b['firstname']} ${b['lastname']}`;
      valueA = nameA;
      valueB = nameB;
    }

    if (column === 'challengeName') {
      const nameA = a['name_EN'] || a['name_HU'];
      const nameB = b['name_EN'] || b['name_HU'];
      valueA = nameA;
      valueB = nameB;
    }

    if (column === 'quizName') {
      const nameA = a['title_EN'] || a['title_HU'];
      const nameB = b['title_EN'] || b['title_HU'];
      valueA = nameA;
      valueB = nameB;
    }

    if (column === 'organisation') {
      const nameA = (a[column]?.companyName || '').toLowerCase();
      const nameB = (b[column]?.companyName || '').toLowerCase();
      if (!nameA && !nameB) return 0;
      if (!nameA) return isAsc ? 1 : -1;
      if (!nameB) return isAsc ? -1 : 1;
      return (isAsc ? 1 : -1) * nameA.localeCompare(nameB);
    }

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return (isAsc ? 1 : -1) * valueA.localeCompare(valueB);
    } else if (Array.isArray(valueA) && Array.isArray(valueB)) {
      const separator = ',';
      valueA = valueA.join(separator);
      valueB = valueB.join(separator);
      return (isAsc ? 1 : -1) * valueA.localeCompare(valueB);
    } else if (typeof valueA === 'number' && typeof valueB === 'number') {
      return (isAsc ? 1 : -1) * (valueA - valueB);
    } else {
      return 0;
    }
  });
}

export const handleSelectAllClick = (
  checked: boolean,
  arr: { id: string }[],
): string[] => (checked ? arr.map((n) => n.id) : []);
