import {
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import ErrorText from 'components/ErrorText/ErrorText';
import SnackbarContext from 'contexts/snackbarContext';
import {
  assignCompobot,
  getCompobotById,
  reAssignCompobot,
} from 'services/compobot.service';
import { getOrganizations } from 'services/organisation.service';
import { OrganisationType } from 'types/organisations.type';

type AssignCompobotFormType = {
  organisationId: string;
  deviceId: string;
};

const CustomForm = styled('form')`
  width: 100%;
`;

export default function AssignCompobotForm() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<AssignCompobotFormType>();
  const { handleOpen } = useContext(SnackbarContext);
  const [organisationList, setOrganisationList] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getOrganizations().then((response) => {
      setOrganisationList(response.data);
    });
  }, []);

  useEffect(() => {
    if (id) {
      getCompobotById(id).then(({ data }) => {
        setValue('organisationId', data.organisation.id);
      });
    }
  }, [id]);

  const onSubmit = ({ organisationId, deviceId }: AssignCompobotFormType) => {
    if (id) {
      reAssignCompobot(id, organisationId)
        .then(() => {
          handleOpen(t('assignCompobotForm.reassignSuccessMessage'), 'success');
          navigate('/compobots');
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {
      assignCompobot(organisationId, deviceId)
        .then(() => {
          reset();
          handleOpen(t('assignCompobotForm.assingSuccessMessage'), 'success');
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  };

  return (
    <Card sx={{ padding: '40px', maxWidth: '600px' }}>
      <CustomForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          {!id && (
            <Grid item xs={12}>
              <TextField
                error={errors.deviceId ? true : false}
                fullWidth
                autoComplete="text"
                type="text"
                {...register('deviceId', { required: true })}
                label={t('formFields.deviceId')}
              />
              {errors.deviceId && (
                <ErrorText>{t('formErrors.deviceIdRequired')}</ErrorText>
              )}
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="label">
                {t('formFields.organisation')}
              </InputLabel>
              <Controller
                name="organisationId"
                control={control}
                rules={{ required: 'Organisation Required' }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    label={t('formFields.organisation')}
                    labelId="label"
                    error={errors.organisationId ? true : false}
                  >
                    {organisationList.map((organisation: OrganisationType) => {
                      return (
                        <MenuItem
                          key={organisation?.id}
                          value={organisation?.id}
                        >
                          {organisation?.companyName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                defaultValue=""
              />
            </FormControl>
            {errors.organisationId && (
              <ErrorText>{t('formErrors.organisationRequired')}</ErrorText>
            )}
          </Grid>
        </Grid>
        <Button variant="contained" sx={{ marginTop: '20px' }} type="submit">
          {t('formFields.submitButtonLabel')}
        </Button>
      </CustomForm>
    </Card>
  );
}
