import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CreateOrganizationForm from 'components/CreateOrganizationForm/CreateOrganizationForm';
import PageHeader from 'components/PageHeader/PageHeader';

export default function CreateOrganizationPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <Container>
      <PageHeader
        title={
          id
            ? t('createOrganisationPage.titleModify')
            : t('createOrganisationPage.titleCreate')
        }
        hasButton={false}
        hasBackButton={true}
      />
      <CreateOrganizationForm />
    </Container>
  );
}
