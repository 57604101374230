import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getChallengeById } from 'services/challenge.service';
import { getGroups } from 'services/group.service';
import { getOrganizations } from 'services/organisation.service';
import { ChallengeType } from 'types/challenges.type';
import { GroupType } from 'types/groups.type';
import { OrganisationType } from 'types/organisations.type';
import PageHeader from 'components/PageHeader/PageHeader';

export default function ViewChallengePage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [groups, setGroups] = useState<GroupType[]>([]);
  const [organisations, setOrganisations] = useState<OrganisationType[]>([]);

  useEffect(() => {
    if (id) {
      getChallengeById(id).then(({ data }: { data: ChallengeType }) => {
        setChallenge(data);
      });
    }
  }, [id]);

  useEffect(() => {
    if (challenge) {
      getGroups().then(({ data }: { data: GroupType[] }) => {
        setGroups(data);
      });

      getOrganizations().then(({ data }: { data: OrganisationType[] }) => {
        setOrganisations(data);
      });
    }
  }, [challenge]);

  const getGroupNames = (groupIds: string[]) => {
    return groupIds.map((id) => {
      const group = groups.find((group) => group.id === id);
      return group ? group.displayName : '';
    });
  };

  const getOrganisationNames = (organisationIds: string[]) => {
    return organisationIds.map((id) => {
      const organisation = organisations.find(
        (organisation) => organisation.id === id,
      );
      return organisation ? organisation.companyName : '';
    });
  };

  return (
    <Container maxWidth="xl">
      <PageHeader
        title={t('formFields.challengeView')}
        hasButton={false}
        hasBackButton={true}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.name_hun')}
            variant="outlined"
            value={challenge ? challenge.name_HU : ''}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.name_eng')}
            variant="outlined"
            value={challenge ? challenge.name_EN : ''}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.shortDescription_hun')}
            variant="outlined"
            value={challenge ? challenge.shortDescription_HU : ''}
            disabled
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.shortDescription_eng')}
            variant="outlined"
            value={challenge ? challenge.shortDescription_EN : ''}
            disabled
            multiline
            rows={2}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.longDescription_hun')}
            variant="outlined"
            value={challenge ? challenge.longDescription_HU : ''}
            disabled
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.longDescription_eng')}
            variant="outlined"
            value={challenge ? challenge.longDescription_EN : ''}
            disabled
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.targetScore')}
            variant="outlined"
            value={challenge ? challenge.targetScore : ''}
            disabled
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.startDate')}
            variant="outlined"
            value={
              challenge && challenge.startDate
                ? new Date(challenge.startDate).toISOString().split('T')[0]
                : ''
            }
            disabled
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.endDate')}
            variant="outlined"
            value={
              challenge && challenge.endDate
                ? new Date(challenge.endDate).toISOString().split('T')[0]
                : ''
            }
            disabled
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.autoInvite')}
            variant="outlined"
            value={
              challenge?.autoInvite
                ? t('formFields.autoInviteEnabled')
                : t('formFields.autoInviteDisabled')
            }
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.orgs')}
            variant="outlined"
            value={
              challenge
                ? getOrganisationNames(challenge?.organisationIds ?? [])
                : ''
            }
            disabled
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t('formFields.groups')}
            variant="outlined"
            value={challenge ? getGroupNames(challenge?.groupIds ?? []) : ''}
            disabled
            multiline
            rows={4}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
