/* eslint-disable indent */
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from '@mui/material';

import ErrorText from 'components/ErrorText/ErrorText';
import SnackbarContext from 'contexts/snackbarContext';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getChallenges } from 'services/challenge.service';
import {
  assignQuiz,
  createQuiz,
  getQuizById,
  modifyQuiz,
  removeAllChallenges,
} from 'services/quiz.service';
import { ChallengeType } from 'types/challenges.type';
import { CreateQuizType, QuizType } from 'types/quizes.type';

export default function CreateQuizForm() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm<CreateQuizType>();

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'questions',
  });

  const [challengeList, setChallengeList] = useState<ChallengeType[]>([]);
  const navigate = useNavigate();
  const { handleOpen } = useContext(SnackbarContext);
  const [assignedChallengeIds, setAssignedChallengeIds] = useState<string[]>(
    [],
  );

  useEffect(() => {
    const fetchData = async () => {
      reset();
      const response = await getChallenges();
      setChallengeList(response.data);

      if (id) {
        const { data }: { data: QuizType } = await getQuizById(id);
        setValue('questions', [...fields]);
        setValue('title_EN', data.title_EN);
        setValue('title_HU', data.title_HU);
        setValue('scoreForCorrect', data.scoreForCorrect);
        data.questions.map((question) => {
          append({
            text_EN: question.text_EN,
            text_HU: question.text_HU,
            answers: question.answers.map((answer) => ({
              text_EN: answer.text_EN,
              text_HU: answer.text_HU,
              isCorrect: answer.isCorrect,
              id: answer.id,
            })),
            uuid: question['id'],
          });
        });

        if (data.challenges) {
          setValue(
            'challengeIds',
            data.challenges.map((challenge) => challenge.id),
          );
        } else {
          setValue('challengeIds', []);
        }
      }
    };

    fetchData();
  }, [id]);

  const onSubmit = (quiz: CreateQuizType) => {
    quiz.scoreForCorrect = Number(quiz.scoreForCorrect);

    if (id) {
      getChallenges()
        .then(() => {
          const quizDto = {
            ...quiz,
          } as CreateQuizType;

          quizDto.questions.forEach((question) => {
            question.id = question.uuid;
            delete question.uuid;
          });

          modifyQuiz(id, quizDto)
            .then(() => {
              if (id) {
                return removeAllChallenges(id);
              }
            })

            .then(() => {
              if (id && assignedChallengeIds.length) {
                return assignQuiz(id, { challengeIds: assignedChallengeIds });
              }
            })

            .then(() => {
              handleOpen(
                t('createQuizForm.quizModifiedSuccessMassage'),
                'success',
              );
              navigate('/quiz');
            })

            .catch(({ data }) => {
              handleOpen(data.message);
            });
        })

        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {
      createQuiz(quiz)
        .then((response) => {
          const quizId = response.data.id;

          if (assignedChallengeIds.length > 0) {
            return assignQuiz(quizId, { challengeIds: assignedChallengeIds })
              .then(() => {
                handleOpen(
                  t('createQuizForm.quizCreatedSuccessMassage'),
                  'success',
                );
                navigate('/quiz');
              })
              .catch(({ data }) => {
                handleOpen(data.message);
              });
          }
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  };

  const handleAddQuestion = () => {
    append({
      text_EN: '',
      text_HU: '',
      answers: [
        { text_EN: '', text_HU: '', isCorrect: false, id: undefined },
        { text_EN: '', text_HU: '', isCorrect: false, id: undefined },
      ],
      uuid: undefined,
    });
  };

  const handleRemoveQuestion = (index: number) => {
    remove(index);
  };

  const handleAddAnswer = (questionIndex: number) => {
    const question = fields[questionIndex];
    update(questionIndex, {
      ...question,
      answers: [
        ...question.answers,
        { text_EN: '', text_HU: '', isCorrect: false },
      ],
    });
  };

  const handleRemoveAnswer = (questionIndex: number, answerIndex: number) => {
    const question = fields[questionIndex];
    const updatedAnswers = question.answers.filter(
      (_, index) => index !== answerIndex,
    );
    update(questionIndex, { ...question, answers: updatedAnswers });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* Title fields */}
        <Grid item xs={6}>
          <Controller
            name="title_EN"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.title_EN ? true : false}
                fullWidth
                autoComplete="text"
                type="text"
                label={t('formFields.title_eng')}
                inputProps={{ maxLength: 80 }}
              />
            )}
          />
          {errors.title_EN && errors.title_EN.type === 'required' && (
            <ErrorText>{t('formErrors.titleRequired')}</ErrorText>
          )}
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="title_HU"
            defaultValue=""
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                error={errors.title_HU ? true : false}
                fullWidth
                autoComplete="text"
                type="text"
                label={t('formFields.title_hun')}
                inputProps={{ maxLength: 80 }}
              />
            )}
          />
          {errors.title_HU && errors.title_HU.type === 'required' && (
            <ErrorText>{t('formErrors.titleRequired')}</ErrorText>
          )}
        </Grid>

        {/* Score for correct field */}
        <Grid item xs={12}>
          <Controller
            name="scoreForCorrect"
            control={control}
            defaultValue={1}
            render={({ field }) => (
              <TextField
                {...field}
                type="number"
                error={errors.scoreForCorrect ? true : false}
                fullWidth
                label={t('formFields.scoreForCorrect')}
                inputProps={{ min: 1, max: 999999999 }}
              />
            )}
          />
        </Grid>

        {/* Challenge select field */}
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="label">
              {t('formFields.selectChallenge')}
            </InputLabel>
            <Controller
              name="challengeIds"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  value={Array.isArray(value) ? value : []}
                  onChange={(event) => {
                    const selectedValues = event.target.value as string[];

                    if (selectedValues.includes('')) {
                      setAssignedChallengeIds([]);
                      onChange([]);
                    } else {
                      setAssignedChallengeIds(selectedValues);
                      onChange(selectedValues);
                    }
                  }}
                  label={t('formFields.selectChallenge')}
                  renderValue={(selected) => {
                    if (Array.isArray(selected) && selected.length === 0) {
                      return t('quizesPage.unAssigned');
                    }
                    return selected
                      .map(
                        (selectedChallengeId) =>
                          challengeList.find(
                            (challenge) => challenge.id === selectedChallengeId,
                          )?.name_EN,
                      )
                      .join(', ');
                  }}
                  labelId="label"
                  error={errors.challenges ? true : false}
                  multiple
                >
                  <MenuItem value="">{t('quizesPage.unAssigned')}</MenuItem>
                  {challengeList.length > 0 &&
                    challengeList.map((challenge: ChallengeType) => (
                      <MenuItem
                        sx={{
                          color: 'grey.900',
                          '&.Mui-selected': {
                            backgroundColor: 'rgb(0, 167, 124, 0.7)',
                            color: 'white',
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: 'rgb(0, 167, 124, 0.7)',
                          },
                        }}
                        key={challenge.id}
                        value={challenge.id}
                      >
                        {challenge.name_EN}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        {/* Questions fields */}
        {fields.map((question, questionIndex) => (
          <React.Fragment key={questionIndex}>
            <Grid xs={1}></Grid>
            <Grid
              item
              xs={5}
              sx={{
                marginTop: '20px',
                borderTop: '1px solid lightgray',
                borderLeft: '1px solid lightgray',
              }}
            >
              <Controller
                name={`questions.${questionIndex}.text_EN`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={
                      errors.questions &&
                      errors.questions[questionIndex] &&
                      errors.questions?.[questionIndex]?.text_EN
                        ? true
                        : false
                    }
                    fullWidth
                    autoComplete="text"
                    type="text"
                    label={t('formFields.question_eng')}
                    inputProps={{ maxLength: 256 }}
                  />
                )}
              />
              {errors.questions?.[questionIndex]?.text_EN &&
                errors.questions?.[questionIndex]?.text_EN?.type ===
                  'required' && (
                  <ErrorText>{t('formErrors.questionRequired')}</ErrorText>
                )}
            </Grid>

            <Grid
              item
              xs={5}
              sx={{
                marginTop: '20px',
                borderTop: '1px solid lightgray',
              }}
            >
              <Controller
                name={`questions.${questionIndex}.text_HU`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={
                      errors.questions &&
                      errors.questions[questionIndex] &&
                      errors.questions[questionIndex]?.text_HU
                        ? true
                        : false
                    }
                    fullWidth
                    autoComplete="text"
                    type="text"
                    label={t('formFields.question_hun')}
                    inputProps={{ maxLength: 256 }}
                  />
                )}
              />
              {errors.questions?.[questionIndex]?.text_HU &&
                errors.questions?.[questionIndex]?.text_HU?.type ===
                  'required' && (
                  <ErrorText>{t('formErrors.questionRequired')}</ErrorText>
                )}
            </Grid>

            {/* Remove Question Button */}
            <Grid
              item
              xs={1}
              sx={{
                marginTop: '20px',
                borderTop: '1px solid lightgray',
                justifyContent: 'space-around',
                display: 'flex',
              }}
            >
              {questionIndex > 0 && (
                <IconButton
                  color="primary"
                  onClick={() => handleRemoveQuestion(questionIndex)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </Grid>

            {/* Answers fields */}
            {question.answers.map((answer, answerIndex) => (
              <React.Fragment key={answerIndex}>
                <Grid xs={2}></Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    borderLeft: '1px solid lightgray',
                  }}
                >
                  <Controller
                    name={`questions.${questionIndex}.answers.${answerIndex}.text_EN`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={
                          errors.questions?.[questionIndex]?.answers?.[
                            answerIndex
                          ]?.text_EN
                            ? true
                            : false
                        }
                        fullWidth
                        autoComplete="text"
                        type="text"
                        label={t('formFields.answers_eng')}
                        inputProps={{ maxLength: 30 }}
                      />
                    )}
                  />
                  {errors.questions?.[questionIndex]?.answers?.[answerIndex]
                    ?.text_EN &&
                    errors.questions?.[questionIndex]?.answers?.[answerIndex]
                      ?.text_EN?.type === 'required' && (
                      <ErrorText>{t('formErrors.answersRequired')}</ErrorText>
                    )}
                </Grid>

                <Grid item xs={4}>
                  <Controller
                    name={`questions.${questionIndex}.answers.${answerIndex}.text_HU`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={
                          errors.questions?.[questionIndex]?.answers?.[
                            answerIndex
                          ]?.text_HU
                            ? true
                            : false
                        }
                        fullWidth
                        autoComplete="text"
                        type="text"
                        label={t('formFields.answers_hun')}
                        inputProps={{ maxLength: 30 }}
                      />
                    )}
                  />
                  {errors.questions?.[questionIndex]?.answers?.[answerIndex]
                    ?.text_HU &&
                    errors.questions?.[questionIndex]?.answers?.[answerIndex]
                      ?.text_HU?.type === 'required' && (
                      <ErrorText>{t('formErrors.answersRequired')}</ErrorText>
                    )}
                </Grid>

                {/* Radio Groups */}
                <Grid item xs={1}>
                  <Controller
                    name={`questions.${questionIndex}.answers.${answerIndex}.isCorrect`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { value, onChange } }) => (
                      <FormControl component="fieldset" required>
                        <FormControlLabel
                          key={answerIndex}
                          name={`questions.${questionIndex}`}
                          control={
                            <Radio
                              checked={value}
                              onChange={() => {
                                onChange(true);
                                question.answers.forEach((_, index) => {
                                  if (index !== answerIndex) {
                                    setValue(
                                      `questions.${questionIndex}.answers.${index}.isCorrect`,
                                      false,
                                    );
                                  }
                                });
                              }}
                              inputProps={{ required: true }}
                            />
                          }
                          label={t('formFields.isCorrect')}
                        />
                      </FormControl>
                    )}
                  />
                </Grid>

                {/* Remove Answer Button */}
                {question.answers.length > 2 && (
                  <Grid item xs={1}>
                    {question.answers.length > 0 && (
                      <IconButton
                        color="primary"
                        onClick={() =>
                          handleRemoveAnswer(questionIndex, answerIndex)
                        }
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    )}
                  </Grid>
                )}
              </React.Fragment>
            ))}

            {/* Add Answer Button */}
            {question.answers.length < 4 && (
              <>
                <Grid xs={2}></Grid>
                <Grid item xs={10}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleAddAnswer(questionIndex)}
                  >
                    {t('formFields.addAnswer')}
                  </Button>
                </Grid>
              </>
            )}
          </React.Fragment>
        ))}

        {/* Add Question Button */}
        <Grid xs={1}></Grid>
        <Grid item xs={11}>
          <Button
            sx={{ marginTop: '40px' }}
            variant="contained"
            color="primary"
            onClick={handleAddQuestion}
          >
            {t('formFields.addQuestion')}
          </Button>
        </Grid>
      </Grid>

      {/* Submit Button */}
      <Button variant="contained" sx={{ marginTop: '56px' }} type="submit">
        {t('formFields.submitButtonLabel')}
      </Button>
    </form>
  );
}
