import { Theme } from '@mui/material';
import { merge } from 'lodash';
import Button from './Button';
import Card from './Card';
import Table from './Table';
import Tooltip from './Tooltip';

export default function ComponentsOverrides(theme: Theme) {
  return merge(Table(theme), Card(theme), Button(theme), Tooltip());
}
