import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { theme } from 'theme';

import SnackbarContext from 'contexts/snackbarContext';
import { generatePin } from 'services/organisation.service';

type PinPopupProps = {
  open: boolean;
  id: string;
  pinCode: number | null;
  handleClose: () => void;
};

const PinPopup = ({ open, id, pinCode, handleClose }: PinPopupProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(open);
  const [confirmText, setConfirmText] = useState(false);
  const [generatedCode, setGeneratedCode] = useState<number | null>(null);
  const { handleOpen } = useContext(SnackbarContext);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const onClose = () => {
    handleClose();
    setTimeout(() => {
      setGeneratedCode(null);
    }, 200);
  };

  const generateHandler = () => {
    if (confirmText) {
      generatePin(id).then(({ data }) => {
        setGeneratedCode(data.PIN);
        handleOpen(t('pinPopup.pinChangedSuccessMessage'), 'success');
        setConfirmText(false);
      });
    } else {
      setConfirmText(true);
    }
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
          padding: '20px',
          width: '540px',
        },
      }}
    >
      <DialogTitle sx={{ padding: 0 }}>{t('pinPopup.title')}</DialogTitle>
      <Grid
        container
        spacing={2}
        sx={{ justifyContent: 'space-between', padding: '20px 0px' }}
      >
        <Grid item>
          <Typography>{t('pinPopup.current')}</Typography>
          <Typography
            sx={{
              fontWeight: 600,
            }}
          >
            {generatedCode ?? pinCode}
          </Typography>
        </Grid>
      </Grid>
      {confirmText && (
        <Typography color={theme.palette.primary.main}>
          {t('pinPopup.confirmNewPin')}
        </Typography>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('pinPopup.cancel')}
        </Button>
        {confirmText && (
          <Button
            onClick={generateHandler}
            variant="contained"
            startIcon={<RefreshIcon />}
          >
            {t('formFields.submitButtonLabel')}
          </Button>
        )}
        {!confirmText && (
          <Button
            onClick={generateHandler}
            variant="contained"
            startIcon={<RefreshIcon />}
          >
            {t('pinPopup.generateNewPin')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PinPopup;
