/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getQuizById } from 'services/quiz.service';
import PageHeader from 'components/PageHeader/PageHeader';
import { QuizType } from 'types/quizes.type';

export default function ViewQuizPage() {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [quiz, setQuiz] = useState<QuizType | null>(null);

  useEffect(() => {
    if (id) {
      getQuizById(id).then(({ data }: { data: QuizType }) => {
        setQuiz(data);
      });
    }
  }, [id]);

  return (
    <Container maxWidth="xl">
      <PageHeader
        title={t('formFields.quizView')}
        hasButton={false}
        hasBackButton={true}
      />
      <form>
        <Grid container spacing={2}>
          {/* Title fields */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t('formFields.title_eng')}
              variant="outlined"
              value={quiz ? quiz.title_EN : ''}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={t('formFields.title_hun')}
              variant="outlined"
              value={quiz ? quiz.title_HU : ''}
              disabled
            />
          </Grid>

          {/* Score for correct field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('formFields.scoreForCorrect')}
              variant="outlined"
              value={quiz ? quiz.scoreForCorrect : ''}
              disabled
              type="number"
            />
          </Grid>

          {/* Challenge select field */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t('formFields.selectChallenge')}
              variant="outlined"
              value={
                quiz && quiz.challenges?.length > 0
                  ? quiz.challenges
                      .map((challenge) => challenge.name_EN)
                      .join(', ')
                  : t('quizesPage.notAssignedChallenge')
              }
              disabled
            />
          </Grid>

          {/* Questions fields */}
          {quiz?.questions.map((question, questionIndex) => (
            <React.Fragment key={questionIndex}>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label={t('formFields.question_eng')}
                  variant="outlined"
                  value={question.text_EN}
                  disabled
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label={t('formFields.question_hun')}
                  variant="outlined"
                  value={question.text_HU}
                  disabled
                />
              </Grid>
              {/* Answers fields */}
              {question.answers.map((answer, answerIndex) => (
                <React.Fragment key={answerIndex}>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={t('formFields.answers_eng')}
                      variant="outlined"
                      value={answer.text_EN}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label={t('formFields.answers_hun')}
                      variant="outlined"
                      value={answer.text_HU}
                      disabled
                    />
                  </Grid>
                  {/* Is Correct field */}
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      label={t('formFields.isCorrect')}
                      variant="outlined"
                      value={
                        answer.isCorrect
                          ? t('formFields.isCorrectYes')
                          : t('formFields.isCorrectNo')
                      }
                      disabled
                    />
                  </Grid>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </form>
    </Container>
  );
}
