/* eslint-disable indent */
import 'react-calendar/dist/Calendar.css';
import 'react-date-picker/dist/DatePicker.css';
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from '@mui/material';
import { addDays, addMonths, endOfDay, startOfDay } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import WordCounter from '../WordCounter/WordCounter';

import ErrorText from 'components/ErrorText/ErrorText';
import SnackbarContext from 'contexts/snackbarContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createChallenge,
  getChallengeById,
  modifyChallenge,
} from 'services/challenge.service';
import { getOrganizations } from 'services/organisation.service';
import { ChallengeType, CreateChallengeType } from 'types/challenges.type';
import { OrganisationType } from 'types/organisations.type';
import { GroupType } from 'types/groups.type';
import { getGroups } from 'services/group.service';

const SHORT_DESC_MAX_LENGTH = 150;
const LONG_DESC_MAX_LENGTH = 290;

export default function CreateChallengeForm() {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<CreateChallengeType>();
  const navigate = useNavigate();
  const { handleOpen } = useContext(SnackbarContext);
  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const [organisationList, setOrganisationList] = useState<OrganisationType[]>(
    [],
  );
  const [groupList, setGroupList] = useState<GroupType[]>([]);

  const [shortDescriptionHUCount, setShortDescriptionHUCount] = useState(0);
  const [shortDescriptionENCount, setShortDescriptionENCount] = useState(0);
  const [longDescriptionHUCount, setLongDescriptionHUCount] = useState(0);
  const [longDescriptionENCount, setLongDescriptionENCount] = useState(0);

  const shortDescriptionChangeHandler = (value: string, lang: 'hu' | 'en') => {
    const count = value.length;

    if (lang === 'hu') {
      setShortDescriptionHUCount(count);
    }

    if (lang === 'en') {
      setShortDescriptionENCount(count);
    }
  };

  const longDescriptionChangeHandler = (value: string, lang: 'hu' | 'en') => {
    const count = value.length;

    if (lang === 'hu') {
      setLongDescriptionHUCount(count);
    }

    if (lang === 'en') {
      setLongDescriptionENCount(count);
    }
  };

  useEffect(() => {
    getOrganizations()
      .then((orgResponse) => {
        const allOrgs = orgResponse.data.map((org: OrganisationType) => ({
          ...org,
          type: 'organisation',
        }));
        setOrganisationList(allOrgs);
        return getGroups();
      })
      .then((groupResponse) => {
        const allGroups = groupResponse.data.map((group: GroupType) => ({
          ...group,
          type: 'group',
        }));
        setGroupList(allGroups);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    if (id) {
      getChallengeById(id).then(({ data }: { data: ChallengeType }) => {
        setValue('name_HU', data.name_HU);
        setValue('name_EN', data.name_EN);
        setValue('shortDescription_HU', data.shortDescription_HU);
        setValue('shortDescription_EN', data.shortDescription_EN);
        setValue('longDescription_HU', data.longDescription_HU);
        setValue('longDescription_EN', data.longDescription_EN);
        setValue('targetScore', data.targetScore);
        setValue('startDate', new Date(data.startDate));
        setValue('endDate', new Date(data.endDate));
        setValue('organisationIds', data.organisationIds);
        setValue('groupIds', data.groupIds);
        setValue('hasCO2target', data.CO2target !== 0);
        setValue('CO2target', data.CO2target);
        setValue('autoInvite', data.autoInvite);
        setShortDescriptionHUCount(data.shortDescription_HU.length);
        setShortDescriptionENCount(data.shortDescription_EN.length);
        setLongDescriptionHUCount(data.longDescription_HU.length);
        setLongDescriptionENCount(data.longDescription_EN.length);
      });
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasCO2target') {
        if (value.hasCO2target) {
          setValue('CO2target', 1000);
        } else {
          setValue('CO2target', 0);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const onSubmit = (challenge: CreateChallengeType) => {
    if (challenge.organisationIds?.length === 0) {
      challenge.organisationIds = undefined;
    }

    if (challenge.groupIds?.length === 0) {
      challenge.groupIds = undefined;
    }

    if (id) {
      if (
        new Date(challenge.startDate ?? new Date('1990.01.01')) < new Date()
      ) {
        challenge.startDate = undefined;
      }

      modifyChallenge(id, challenge)
        .then(() => {
          handleOpen(
            t('createChallengeForm.challengeModifiedSuccessMassage'),
            'success',
          );
          navigate('/challenges');
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    } else {
      createChallenge(challenge)
        .then(() => {
          handleOpen(
            t('createChallengeForm.challengeCreatedSuccessMassage'),
            'success',
          );
          navigate('/challenges');
        })
        .catch(({ data }) => {
          handleOpen(data.message);
        });
    }
  };

  return (
    <Container style={{ maxWidth: 1200 }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2}>
          {/* Name fields */}
          <Grid item xs={6}>
            <Controller
              name="name_HU"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                maxLength: 30,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.name_HU ? true : false}
                  fullWidth
                  autoComplete="text"
                  type="text"
                  label={t('formFields.name_hun')}
                />
              )}
            />
            {errors.name_HU && errors.name_HU.type === 'required' && (
              <ErrorText>{t('formErrors.nameRequired')}</ErrorText>
            )}
            {errors.name_HU && errors.name_HU.type === 'maxLength' && (
              <ErrorText>{t('formErrors.nameTooLong')}</ErrorText>
            )}
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="name_EN"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                maxLength: 30,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.name_EN ? true : false}
                  fullWidth
                  autoComplete="text"
                  type="text"
                  label={t('formFields.name_eng')}
                />
              )}
            />
            {errors.name_EN && errors.name_EN.type === 'required' && (
              <ErrorText>{t('formErrors.nameRequired')}</ErrorText>
            )}
            {errors.name_EN && errors.name_EN.type === 'maxLength' && (
              <ErrorText>{t('formErrors.nameTooLong')}</ErrorText>
            )}
          </Grid>

          {/* Short Description fields */}
          <Grid item xs={6}>
            <Controller
              name="shortDescription_HU"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                maxLength: SHORT_DESC_MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.shortDescription_HU ? true : false}
                  fullWidth
                  type="text"
                  label={t('formFields.shortDescription_hun')}
                  multiline
                  rows={2}
                  onChange={(e) => {
                    field.onChange(e);
                    shortDescriptionChangeHandler(e.target.value, 'hu');
                  }}
                />
              )}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <WordCounter
                currentWordCount={shortDescriptionHUCount}
                maxCount={SHORT_DESC_MAX_LENGTH}
              />
              {errors.shortDescription_HU &&
                errors.shortDescription_HU.type === 'required' && (
                  <ErrorText>
                    {t('formErrors.shortDescriptionRequired')}
                  </ErrorText>
                )}

              {errors.shortDescription_HU &&
                errors.shortDescription_HU.type === 'maxLength' && (
                  <ErrorText>
                    {t('formErrors.shortDescriptionTooLong')}
                  </ErrorText>
                )}
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="shortDescription_EN"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                maxLength: SHORT_DESC_MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.shortDescription_EN ? true : false}
                  fullWidth
                  type="text"
                  label={t('formFields.shortDescription_eng')}
                  multiline
                  rows={2}
                  onChange={(e) => {
                    field.onChange(e);
                    shortDescriptionChangeHandler(e.target.value, 'en');
                  }}
                />
              )}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <WordCounter
                currentWordCount={shortDescriptionENCount}
                maxCount={SHORT_DESC_MAX_LENGTH}
              />
              {errors.shortDescription_EN &&
                errors.shortDescription_EN.type === 'required' && (
                  <ErrorText>
                    {t('formErrors.shortDescriptionRequired')}
                  </ErrorText>
                )}

              {errors.shortDescription_EN &&
                errors.shortDescription_EN.type === 'maxLength' && (
                  <ErrorText>
                    {t('formErrors.shortDescriptionTooLong')}
                  </ErrorText>
                )}
            </Grid>
          </Grid>

          {/* Long Description fields */}
          <Grid item xs={6}>
            <Controller
              name="longDescription_HU"
              control={control}
              defaultValue=""
              rules={{
                maxLength: LONG_DESC_MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.longDescription_HU ? true : false}
                  fullWidth
                  type="text"
                  label={t('formFields.longDescription_hun')}
                  multiline
                  rows={4}
                  onChange={(e) => {
                    field.onChange(e);
                    longDescriptionChangeHandler(e.target.value, 'hu');
                  }}
                />
              )}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <WordCounter
                currentWordCount={longDescriptionHUCount}
                maxCount={LONG_DESC_MAX_LENGTH}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="longDescription_EN"
              control={control}
              defaultValue=""
              rules={{
                maxLength: LONG_DESC_MAX_LENGTH,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={errors.longDescription_EN ? true : false}
                  fullWidth
                  type="text"
                  label={t('formFields.longDescription_eng')}
                  multiline
                  rows={4}
                  onChange={(e) => {
                    field.onChange(e);
                    longDescriptionChangeHandler(e.target.value, 'en');
                  }}
                />
              )}
            />
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <WordCounter
                currentWordCount={longDescriptionENCount}
                maxCount={LONG_DESC_MAX_LENGTH}
              />
            </Grid>
          </Grid>

          {/* CO2 fields */}
          <Grid item xs={12}>
            <Controller
              name="hasCO2target"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  color="primary"
                  inputProps={{ 'aria-label': 'hasCO2target checkbox' }}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    field.onChange(isChecked);
                    if (isChecked) {
                      setValue('CO2target', 1000);
                    } else {
                      setValue('CO2target', 0);
                    }
                  }}
                />
              )}
            />
            <Tooltip
              title={t('tableHeaders.CO2Tooltip')}
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 200 }}
            >
              <label htmlFor="co2Goal">{t('tableHeaders.hasCO2target')}</label>
            </Tooltip>
          </Grid>

          {/* Hidden CO2 target field */}
          <Controller
            name="CO2target"
            control={control}
            defaultValue={1000}
            render={({ field }) => <input type="hidden" {...field} />}
          />

          {/* Target Score fields */}
          <Grid item xs={12}>
            <Controller
              name="targetScore"
              control={control}
              defaultValue={1000}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  error={errors.targetScore ? true : false}
                  fullWidth
                  label={t('formFields.targetScore')}
                  InputProps={{ inputProps: { min: 1, max: 999999999 } }}
                />
              )}
            />
            {errors.targetScore && (
              <ErrorText>{t('formErrors.targetScoreRequired')}</ErrorText>
            )}
          </Grid>

          {/* Date fields */}
          <Grid item xs={6}>
            <Typography
              style={{ fontSize: 12, color: theme.palette.grey[600] }}
            >
              {t('formFields.startDate')}
            </Typography>
            <Controller
              control={control}
              name="startDate"
              defaultValue={startOfDay(new Date())}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ? startOfDay(value as Date) : value}
                  onChange={onChange}
                  minDate={watchStartDate ?? new Date()}
                  maxDate={watchEndDate}
                  disabled={
                    watchStartDate &&
                    (watchStartDate < addDays(new Date(), -1) ? true : false)
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              style={{ fontSize: 12, color: theme.palette.grey[600] }}
            >
              {t('formFields.endDate')}
            </Typography>
            <Controller
              control={control}
              name="endDate"
              defaultValue={endOfDay(addMonths(new Date(), 1))}
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  value={value ? endOfDay(value as Date) : value}
                  onChange={onChange}
                  minDate={watchStartDate}
                />
              )}
            />
          </Grid>

          {/* Auto invite checkbox */}
          <Grid item xs={12}>
            <Controller
              name="autoInvite"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  color="primary"
                  inputProps={{ 'aria-label': 'autoInvite checkbox' }}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                />
              )}
            />
            <Tooltip
              title={t('formFields.autoInviteTooltip')}
              arrow
              placement="top"
              TransitionComponent={Zoom}
              TransitionProps={{ timeout: 200 }}
            >
              <label htmlFor="autoInvite">{t('formFields.autoInvite')}</label>
            </Tooltip>
          </Grid>

          {/* Organisation & Group select fields */}
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="organisation-select">
                {t('formFields.selectOrganisation')}
              </InputLabel>
              <Controller
                name="organisationIds"
                control={control}
                rules={{
                  validate: () =>
                    (watch('groupIds') ?? []).length > 0 ||
                    (watch('organisationIds') ?? []).length > 0,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="organisation-select"
                    value={value || []}
                    onChange={(event) => {
                      const selectedOrganisationIds = Array.isArray(
                        event.target.value,
                      )
                        ? event.target.value
                        : [event.target.value];
                      onChange(selectedOrganisationIds);
                    }}
                    label={t('formFields.selectOrganisation')}
                    labelId="organisation-select-label"
                    error={
                      errors.groupIds && errors.organisationIds ? true : false
                    }
                    multiple
                  >
                    {organisationList.map((organisation) => (
                      <MenuItem
                        sx={{
                          color: 'grey.900',
                          '&.Mui-selected': {
                            backgroundColor: 'rgb(0, 167, 124, 0.7)',
                            color: 'white',
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: 'rgb(0, 167, 124, 0.7)',
                          },
                        }}
                        key={organisation.id}
                        value={organisation.id}
                      >
                        {organisation.companyName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.groupIds && errors.organisationIds && (
                <ErrorText>{t('formErrors.onlyOneRequired')}</ErrorText>
              )}
            </FormControl>

            <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
              <InputLabel htmlFor="group-select">
                {t('formFields.selectGroups')}
              </InputLabel>
              <Controller
                name="groupIds"
                control={control}
                rules={{
                  validate: () =>
                    (watch('organisationIds') ?? []).length > 0 ||
                    (watch('groupIds') ?? []).length > 0,
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    id="group-select"
                    value={value || []}
                    onChange={(event) => {
                      const selectedGroupIds = Array.isArray(event.target.value)
                        ? event.target.value
                        : [event.target.value];
                      onChange(selectedGroupIds);
                    }}
                    label={t('formFields.selectGroups')}
                    labelId="group-select-label"
                    error={
                      errors.groupIds && errors.organisationIds ? true : false
                    }
                    multiple
                  >
                    {groupList.map((group) => (
                      <MenuItem
                        sx={{
                          color: 'grey.900',
                          '&.Mui-selected': {
                            backgroundColor: 'rgb(0, 167, 124, 0.7)',
                            color: 'white',
                          },
                          '&.Mui-selected:hover': {
                            backgroundColor: 'rgb(0, 167, 124, 0.7)',
                          },
                        }}
                        key={group.id}
                        value={group.id}
                      >
                        {group.displayName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.groupIds && errors.organisationIds && (
                <ErrorText>{t('formErrors.onlyOneRequired')}</ErrorText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{ marginTop: '20px' }}
          type="submit"
          disabled={!watchStartDate || !watchEndDate}
        >
          {t('formFields.submitButtonLabel')}
        </Button>
      </form>
    </Container>
  );
}
