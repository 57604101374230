import { CreateGroupType } from 'types/groups.type';
import { ApiClient } from './interceptor';

const api = ApiClient.getInstance();

export const getGroups = async () => {
  return await api.get('groups');
};

export const getGroupById = async (id: string) => {
  return await api.get(`groups/${id}`);
};

export const createGroup = async (
  group: CreateGroupType,
  organisationId?: string,
) => {
  const config = organisationId ? { params: { organisationId } } : {};
  return await api.post('groups', group, config);
};

export const modifyGroup = async (id: string, group: CreateGroupType) => {
  return await api.put(`groups/${id}`, group);
};

export const getGroupsForOrganisation = async (orgId: string) => {
  return await api.get(`organisation/groups/${orgId}`);
};

export const searchGroupsForOrganisation = async (
  orgId: string,
  searchTerms: string,
) => {
  return await api.get(`organisation/groups/${orgId}/lookup/${searchTerms}`);
};

export const deleteGroup = async (id: string) => {
  return await api.delete(`groups/${id}`);
};

export const deleteGroupBulk = async (ids: string[]) => {
  return await api.deleteWithParams('groups', { UIds: ids });
};
